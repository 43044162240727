const html = require('choo/html');
const modal = require('./modal');

module.exports = function(state, emit) {
  state.modal = null;
  return html`
    <main class="main">
      ${state.modal && modal(state, emit)}
      <div
        class="flex flex-col items-center bg-white m-4 px-6 py-8 border border-grey-30 md:border-none md:px-12 md:py-16 shadow w-full md:h-full dark:bg-grey-90"
      >
        <h1 class="text-center text-3xl font-bold">
          ${state.translate('legalTitle')}
        </h1>
        <p class="mt-2">${state.translate('legalDateStamp')}</p>
        <div class="overflow-y-scroll py-8 px-12">
          <p class="leading-normal">
            <span>We're using a standard version of Firefox Send from August 2020. Below are the top
              things you should know about Derp Send. You can also view the
              code</span
            >
            <a
              href="https://github.com/mozilla/send/blob/master/docs/metrics.md"
              target="__blank"
              rel="noopener noreferrer"
              class="link-blue hover:underline"
              >here</a
            >.
          </p>
          <ul class="mt-6 leading-normal">
            <li class="mb-4">
              No guarantees: We may suspend or terminate your access to the Services at any time for any reason.
            </li>
            <li class="mb-4">
	      You agree to defend, indemnify and hold harmless us, our contractors, contributors, licensors, and partners, and their respective directors, officers, employees and agents ("Indemnified Parties") from and against any and all third party claims and expenses, including attorneys' fees, arising out of or related to your use of the Services (including, but not limited to, from any content uploaded by you).
            </li>
            <li class="mb-4">
		Disclaimer; Limitation of Liability
		THE SERVICES ARE PROVIDED "AS IS" WITH ALL FAULTS. TO THE EXTENT PERMITTED BY LAW, DERP AND THE INDEMNIFIED PARTIES HEREBY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES THAT THE SERVICES ARE FREE OF DEFECTS, MERCHANTABLE, FIT FOR A PARTICULAR PURPOSE, AND NON-INFRINGING. YOU BEAR THE ENTIRE RISK AS TO SELECTING THE SERVICES FOR YOUR PURPOSES AND AS TO THE QUALITY AND PERFORMANCE OF THE SERVICES, INCLUDING WITHOUT LIMITATION THE RISK THAT YOUR CONTENT IS DELETED OR CORRUPTED OR THAT SOMEONE ELSE ACCESSES YOUR ONLINE ACCOUNTS. THIS LIMITATION WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF IMPLIED WARRANTIES, SO THIS DISCLAIMER MAY NOT APPLY TO YOU.
		EXCEPT AS REQUIRED BY LAW, DERP AND THE INDEMNIFIED PARTIES WILL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR IN ANY WAY RELATING TO THESE TERMS OR THE USE OF OR INABILITY TO USE THE SERVICES, INCLUDING WITHOUT LIMITATION DIRECT AND INDIRECT DAMAGES FOR LOSS OF GOODWILL, WORK STOPPAGE, LOST PROFITS, LOSS OF DATA, AND COMPUTER FAILURE OR MALFUNCTION, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF THE THEORY (CONTRACT, TORT, OR OTHERWISE) UPON WHICH SUCH CLAIM IS BASED. THE COLLECTIVE LIABILITY OF DERP AND THE INDEMNIFIED PARTIES UNDER THIS AGREEMENT WILL NOT EXCEED $1 (ONE DOLLAR). SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES, SO THIS EXCLUSION AND LIMITATION MAY NOT APPLY TO YOU.
            </li>
            <li class="mb-4">
              <b>Content</b>: Derp receives an encrypted copy of the file you
              upload but we cannot access the content or name of your encrypted
              file. By default, files are stored for a maximum of either 24
              hours or 7 days. If you choose a download cap, the file can be
              deleted from our server sooner.
            </li>
            <li class="mb-4">
              <b>Data on your device</b>: So that you can check status or delete
              files, basic information about your uploaded files is stored on
              your local device. This includes our identifier for the file, the
              filename, and the file’s unique download URL. This is cleared if
              you delete your uploaded file or upon visiting Send after the file
              expires. Note, however, that the URL will persist in your browsing
              history (and with whomever you shared it) until manually deleted.
            </li>
            <li class="mb-4">
              <b>Personal data</b>: The following is necessary to provide the
              service:
              <ul class="mt-6 leading-normal">
                <li class="mb-4">
                  <u>IP addresses</u>: We receive IP addresses of downloaders
                  and uploaders as part of our standard server logs. These are
                  retained for 90 days and may be kept longer in backups, and for that period, may be connected to
                  activity of a file’s download URL. Although we develop our
                  services in ways that minimize identification, you should know
                  that it may be possible to correlate the IP address of a Send
                  user to the IP address of other Derp services, if we had any.
                  If there is a match, this could identify the
                  account email address.
                </li>
                <li class="mb-4">
                  <u>Firefox Account</u>: I turned this feature off and increased the limit for everyone.
                </li>
              </ul>
            </li>
            <li class="mb-4">
              <b>Non-personal data</b>: We don't at the moment but at some point in the future may receive the following to improve our
              service and performance:
              <ul class="mt-6 leading-normal">
                <li class="mb-4">
                  <u>Interaction data</u>: This includes information such as
                  number of people sending and receiving files, number of files
                  uploaded and approximate file sizes, percentage of file
                  downloaders who become uploaders, how people engage with the
                  website (time spent, clicks, referrer information, site exit
                  path, use of passwords).
                </li>
                <li class="mb-4">
                  <u>Technical data</u>: This includes information such as
                  operating system, browser, language preference, country,
                  timestamps, duration for file transfer, reasons for errors,
                  reasons for file expiration.
                </li>
              </ul>
            </li>
            <li class="mb-4">
              <b>Third Party Services</b>: None.
            </li>
          </ul>
        </div>
      </div>
    </main>
  `;
};
